/* Note: This css is to prevent useragent styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text !important;
  transition: background-color 5000s ease-in-out 0s !important;
  box-shadow: none !important;
}
/* the above block ends here */

:root {
  --ck-border-radius: 8px;
  --ck-color-focus-border: #9C27B0;
  --ck-color-button-on-color: #9C27B0;
  --ck-color-list-button-hover-background: #CE93D870;
  --ck-color-list-button-on-background-focus: #CE93D8;
}

.ck-content {
  max-height: 70vh;
  min-height: 199px;
}
